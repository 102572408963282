.headline-overview {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 90%;
    gap: 2rem 0;
}

.headline-left {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: space-evenly;
    
}

.headline-right {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-height: auto;
}

.headline-subheading {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    max-width: 80%;
    color: #3e518e;
}

.home-heading {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 66px;
    color: #3966FB;
    line-height: 85px;
}

@media screen and (max-width: 1250px) {
    .home-heading {
        font-size: 55px;
        line-height: 70px;

    }
    .headline-right {
        max-width: 616px;
        width: 100%;
        max-height: auto;
    }
}

@media screen and (min-width: 1250px) {

    .headline-overview {
        width: 80%;
    }

    .home-heading {
        font-size: 55px;
        line-height: 70px;

    }

    .headline-left {
        justify-content: space-evenly;
    }

    .headline-right {
        max-width: 616px;
        width: 100%;
        max-height: auto;
    }
}



@media screen and (max-width: 1120px) {
    .headline-overview {
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
    }
    .headline-left {
        order: 2;
        width: 100%;
        align-items: center;
        gap: 2rem;
    }

    .headline-right {
        max-height: auto;
    }

    .home-heading {
        font-size: 60px;
        line-height: 68px;
        text-align: center;
    }
}

@media screen and (max-width: 750px) {
    .home-heading {
        font-size: 34px;
        line-height: 51px;
    }
    .headline-right {
        max-width: 400px;
        max-height: 400px;
    }
    .home-heading {
        max-width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .home-heading {
        font-size: 36px;
        line-height: 46px;
    }
}
