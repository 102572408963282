.footer {
    color: #939ea4;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
}
.footer-content {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.copyright {
    padding-top: 3.5rem;
    padding-bottom: 1.5rem;
}
.disclaimer {
    padding-bottom: 2rem; 
    color: #b7bbbd;
    font-size: 13px;
}

.disclaimer-link {
    color: rgb(83, 83, 255);
}
.footer-bottom {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-top: 1px solid #cdd1d4;
}
.footer-links {
    display: flex;
    gap: 4rem;
}
.footer-link {
    list-style: none;
}
.socials {
    display: flex;
    gap: 2rem;
}
.social {
    font-size: 20px;
}

@media screen and (max-width: 880px) {
    .footer {
        padding: 3rem 0;
    }
    .footer-links {
        gap: 2rem;
    }
    .footer-content {
        align-items: center;
        gap: 1.5rem;
    }
    .copyright {
        padding: 1rem 0;
    }
    .footer-bottom {
        border-top: none;
        flex-direction: column;
        padding: 0;
        align-items: center;
        gap: 2rem;
    }
}
