.subscribe-button {
    display: flex;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px;
    line-height: 31.09px;
    justify-content: center;
    align-items: center;
    border-radius: 12.9545px;
    padding: 11px 0;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.button1 {
    color: white;
    background: linear-gradient(22.32deg, #b8ddff -49.77%, #3966fb 101.24%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 66%;
}

.button2 {
    color: #3966fb;
    background: linear-gradient(22.32deg, #ebefff -49.77%, #b3daff 101.24%);
    box-shadow: 0px 4px 8px rgba(176, 85, 85, 0.18);
    width: 110%;
}

.subscribe-button:hover {
    opacity: 0.9;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease-in-out;
}

.modal-content {
    background-color: white;
    display: flex;
    flex-direction: column; 
    border: 1px solid #ccc;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    max-width: 50%;
    width: 100%;
    position: relative;
    border-radius: 20px;
    align-items: center;
}

.subscribe-header {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 31.65px;
    line-height: 39.56px;
    text-align: center;
    padding-top: 1.5rem;
    color: #3966fb;
}

.subscriber-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    color: #3e518e;
    text-align: center;
    padding-bottom: 1rem;
}

.sub-img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    width: 50%;
    padding-bottom: 3rem;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0.5rem;
    background: white;
    opacity: 0.65;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

@media screen and (max-width: 1120px) {
    .subscribe-button {
        font-size: 18px;
        line-height: 24px;
        padding: 1rem 1rem;
    }
    .button1 {
        width: 100%;
    }

    .button2 {
        width: 100%;
    }

    .modal-content {
        padding: 1rem;
        max-width: 70%;
        max-height: auto;

    }
}


@media screen and (max-width: 820px) {
    .modal-content {
        padding: 1rem;
        max-width: 80%;
        max-height: auto;
    }
}



@media screen and (max-width: 680px) {

    .subscribe-header {
        font-size: 28px;
        line-height: 40px;
    }
    
    .subscriber-para {
        font-size: 18px;
        line-height: 36px;
    }

    .modal-content {
        padding: 1rem;
        max-width: 90%;
        max-height: 80%;
    }
}

@media screen and (max-width: 480px) {
    .modal-content {
        padding: 1rem;
        max-width: 90%;
        max-height: 90%;

    }
}
