.how-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.introduction {
    display: flex;
    flex-direction: column;
    align-self: center;
    width:46%;
    padding-bottom: 7rem;

}

.introduction-header {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    text-align: center;
    padding-bottom: 2rem;
}

.introduction-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    text-align: center;
}

.special-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    gap: 200px;
}

.sf-header {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    line-height: 58px;
    color: #3966fb;
    text-align: left;
    padding-bottom: 1rem;
}

.sf-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #3e518e;
    text-align: left;
}

.sf-trusted {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content:space-around;
    gap: 4rem;

}

.sf-trusted-left {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 525px;
    height: 200px;

}

.trusted-img {
    display: flex;
    flex-direction: column;
    align-items: right;
    width: 442.39px;
    height: 442px;
}

.sf-purchase {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content:space-around;
}

.sf-purchase-right {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 5rem;
    width: 525px;
    height: 200px;
}

.purchase-img {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 532.35px;
    height: 400.64px;
}

.sf-authentic {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content:space-around;
    padding-bottom: 10rem;
    gap: 2rem;
}

.sf-authentic-left {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 525px;
    height: 230px;
}

.community-img {
    display: flex;
    flex-direction: column;
    align-items: right;
    width: 449.35px;
    height: 424.79px;
}
@media screen and (max-width: 1120px) {
    .sf-trusted, .sf-authentic, .sf-purchase {
        width: 100%;
        justify-content:space-between;
    }

}

@media screen and (max-width: 880px) {
    .introduction {
        width: 70%;
        padding-bottom: 3rem;
        gap: 1rem;
    }


    .introduction-header {
        font-size: 34px;
        line-height: 60px;
    }

    .introduction-para {
        font-size: 20px;
        line-height: 32px;

    }

    .special-features {
        max-width: 100%;
        gap: 10rem;
    }

    .sf-header {
        text-align: left;
        width: 100%;
    }

    .sf-trusted {
        flex-direction: column;
        gap: 5rem;
    }

    .sf-trusted-left {
        display: flex;
        flex-direction: column;
        width: 90%;
        order: 2;

    }

    .trusted-img {
        padding-top: 2rem;
        align-items: center;
        width: 70%;
        height: auto;
        order: 1;
        padding-bottom: 0rem;
    }


    .sf-purchase {
        flex-direction: column;
        gap: 5rem;
    }
    
    .sf-purchase-right {
        width: 90%;
        order: 2;
        padding: 0rem;

    }
    
    .purchase-img {
        align-items: center;
        width: 70%;
        height: auto;
        order: 1;
        padding-bottom: 0rem;
    }


    .sf-authentic {
        flex-direction: column;
        gap: 5rem;
    }
    
    .sf-authentic-left {
        align-items: center;
        width: 90%;
        order: 2;
    }
    
    .authentic-img {
        align-items: right;
        order: 1;
        width: 70%;
        height: auto;
    }

}

@media screen and (max-width: 480px) {
    .trusted-img, .purchase-img, .community-img {
        height: auto;
        width: 90%;
    }

    .introduction-header {
        font-size: 34px;
        line-height: 50px;
    }

    .sf-header {
        font-size: 34px;
        line-height: 54px;
    }
    
    .sf-para {
        font-size: 18px;
        line-height: 30px;
    }
}
