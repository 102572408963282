.features {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 8rem;
    padding-bottom: 5rem;
}
.features-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 90%;
    gap: 0rem;
}
.features-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.features-heading {
    font-size: 43.84px;
    font-weight: 800;
    text-align: center;
}

.feature-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #3e518e;
    text-align: center;
}

.feature-fraction {
    box-sizing: border-box;
    border: 3px solid #afdaff;
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem 2rem;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    background: hsla(0, 0%, 100%, 0.3);
    box-shadow: 5.26027px 0px 21.0411px rgba(167, 167, 167, 0.25);
    border-radius: 26.3014px;
    width: 28%;
    height: 541px;
}
.feature-friends {
    box-sizing: border-box;
    border: 3px solid #ffdfaf;
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem 2rem;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    background: hsla(0, 0%, 100%, 0.3);
    box-shadow: 5.26027px 0px 21.0411px rgba(167, 167, 167, 0.25);
    border-radius: 26.3014px;
    width: 28%;
    height: 541px;
}
.feature-fun {
    box-sizing: border-box;
    border: 3px solid #ffafe9;
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem 2rem;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    background: hsla(0, 0%, 100%, 0.3);
    box-shadow: 5.26027px 0px 21.0411px rgba(167, 167, 167, 0.25);
    border-radius: 26.3014px;
    width: 28%;
    height: 541px;
}
.feature-top {
    height: 100px;
}
.feature-img {
    width: 100px;
}
.feature-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}
.feature-heading-fraction {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #3966fb;
}
.feature-heading-friends {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #ffa800;
}
.feature-heading-fun {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #e23de6;
}
.feature-para-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}

.one-click-invest {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 4rem;
    padding: 6rem 0;
}

.one-click-invest-left {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    padding: 8rem 0;
}

.one-click-heading {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 52.381px;
    line-height: 105px;
    color: #3966fb;
}

.one-click-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #3e518e;
}

.one-click-img {
    display: flex;
    flex-direction: column;
    align-items: right;
    max-width: 100%;
    width: 50%;
    height: auto;
}

.video-element {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 3rem;
}

@media screen and (max-width: 1120px) {
    .features-list {
        width: 80%;
    }

    .one-click-invest {
        max-width: 100%;
        max-height: auto;
        gap: 1rem;
    }

    .one-click-img {
        max-width: 100%;
        max-height: auto;
    }

    .features-heading {
        font-size: clamp(28px, 6vw, 38px);
    }

    .one-click-invest-left {
        max-width: 100%;
    }

    .features-list {
        width: 90%;
        flex-direction: column;
        gap: 4rem;
    }

    .feature-fraction,
    .feature-fun,
    .feature-friends {
        width: 40%;
        min-width: 343px;
    }
}

@media screen and (max-width: 1020px) {
    .one-click-heading {
        font-size: 48px;
        line-height: 80px;
    }
    .one-click-para {
        font-size: 20px;
        line-height: 30px;
    }
}

@media screen and (max-width: 920px) {
    .one-click-invest {
        flex-direction: column;
        width: 95%;
        align-items: center;
        text-align: left;
        gap: 0rem;
        padding-bottom: 0rem;
    }

    .one-click-img {
        width: 90%;
        order: 1;
    }

    .one-click-invest-left {
        padding-top: 4rem;
        order: 2;
        width: 100%;
        align-items: center;
    }

    .one-click-heading {
        font-size: 55px;
        line-height: 96px;
    }

    .one-click-para {
        font-size: 20px;
        line-height: 32px;
    }

    .video-element {
        padding-bottom: 1rem;
    }

    .features-list {
        width: 80%;
        flex-direction: column;
        gap: 4rem;
    }

    .features-heading {
        font-size: clamp(28px, 6vw, 38px);
    }
}

@media screen and (max-width: 680px) {
    .one-click-invest {
        flex-direction: column;
        width: 90%;
        align-items: center;
        text-align: left;
        gap: 0rem;
        padding-bottom: 0rem;
    }

    .one-click-img {
        width: 90%;
        order: 1;
    }

    .one-click-invest-left {
        padding-top: 4rem;
        order: 2;
        width: 88%;
        align-items: center;
    }

    .one-click-heading {
        font-size: 48px;
        line-height: 60px;
        padding-bottom: 1rem;
    }

    .one-click-para {
        font-size: 18px;
        line-height: 32px;
    }

    .video-element {
        padding-bottom: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .features-list {
        width: 90%;
        flex-direction: column;
        gap: 4rem;
    }

    .feature-fraction,
    .feature-fun,
    .feature-friends {
        min-width: 100%;
    }
}

@media screen and (max-width: 330px) {
    .features-heading {
        font-size: 30px;
    }

    .feature-para {
        font-size: 18px;
        line-height: 24px;

    }
}
