@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&family=Poppins:wght@200;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
}

.montserrat {
    font-family: Montserrat;
}

.poppins {
    font-family: Poppins;
}

.heading {
    color: #3966FB;
}


