.thank-you {
    transform-origin: top;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    max-height: 100%;
    z-index: 100;
}

.main-img {
    max-width: 700px;
    max-height: auto;
    width: 100%;
}

.thank-you-heading {
    font-family: 'Poppins';
    padding: 1rem 0;
    color: #3966FB;
    font-weight: 800;
    font-size: 58px;
    line-height: 75px;
}
.thank-you-para {
    font-family: 'Poppins';
    text-align: center;
    font-weight: 400;
    color: #3E518E;
    font-size: 20px;
    line-height: 28px;
    width: 60%;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 0.5rem;
    background: white;
    opacity: 0.65;
    border: none;
    font-size: 1.5em;
}

.reference-bar {
    display: flex;
    box-sizing: border-box;
    max-width:330px;
    width: 60%;
    padding-top: 2rem; 
}
.text-box {
    background-color: white;
    color:#3966FB;
    border: 1.96118px solid #3966FB;
    border-radius: 10px 0px 0px 10px;
    width: 68%;
    height: auto;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 21px;
    padding: 1rem 1.5rem;
}

.copy-btn {
    background-color: #3966FB;
    color: white;
    border: 1.96118px solid #3966FB;
    border-radius: 0px 10px 10px 0px;
    width: 32%;
    height: auto;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 23px;
    text-align: center;
    cursor: pointer;

}

.copy-btn:hover {
    opacity: 0.9;
}

.reminder {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #3966FB;
    padding-top: 0.3rem;
}

.icon-bar {
    display: flex;
    align-items: center;
    padding-top: 1rem;

}

.download {
    max-width: 154px;
    width: 90%;
}


@media screen and (max-width: 900px) {
    .thank-you{
        padding: 3rem 5rem 3rem 5rem;
        max-width: 100%;
        
    }
    .thank-you-heading {
        padding: 0rem;
        font-size: 40px;
    }
    .thank-you-para {
        font-size: 20px;
        width: 80%;
    }

}
@media screen and (max-width: 580px) {
    .thank-you{
        padding: 3rem;
        max-width: 100%;
        width: 100%;
    }
    .thank-you-heading {
        padding: 0rem;
        font-size: 33px;
    }
    .thank-you-para {
        font-size: 19px;
        width: 90%;
    }
    .reference-bar {
        max-width: 80%;
    }
}


@media screen and (max-width: 480px) {
    .thank-you-img {
        max-width: 400px;
        width: 100%;
        height: auto;
    }

    .thank-you-para {
        font-size: 19px;
        width: 90%;
    }
    .download {
        width: 90%;
    }
    
}

@media screen and (max-width: 290px) {
    .thank-you-heading {
        font-size: 31px;
    }

}
