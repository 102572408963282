header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
}
nav {
    max-width: 90%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
a {
    color: inherit;
    text-decoration: none;
}
.logo {
    width: 150px;
    cursor: pointer;
}
.nav-links {
    display: flex;
    align-items: center;
    gap: clamp(2rem, 8vw, 5rem);
    list-style: none;
    margin-right: 1rem;
}
.nav-link {
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    position: relative;
}
.nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transition: transform 0.2s;
    background-color: black;
    transform-origin: right;
}
.nav-link:hover::before {
    transform: scaleX(1);
    transform-origin: left;
}
.hammenu-btn {
    display: none;
    position: relative;
    z-index: 2;
}
.open-ham {
    display: none;
    font-size: 24px;
}
.close-ham {
    display: none;
    position: fixed;
    font-size: 24px;
    color: #260b49;
}
.show-img {
    display: block;
}

@media screen and (max-width: 750px) {
    .logo {
        width: 100px;
    }
}

@media screen and (max-width: 550px) {
    header {
        height: 75px;
    }
    .nav-links {
        position: fixed;
        background-color: #260b49;
        color: white;
        right: -100%;
        top: 0;
        width: 50%;
        height: 100vh;
        z-index: 88;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 6rem 0 0 2rem;
        transition: all 0.2s;
    }
    .show-hammenu {
        right: 0;
        margin: 0;
        background-color: #FAFAFA;
        color: #260b49;
    }
    .hammenu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 99;
    }
}
