.bottom-banner {
    display:flex;
    justify-content: center;
    width: 100%;
    background-image: url(../../Assets/8113055\ 1back.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 0;
}

.information {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width:55%;

}

.information-header {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 40px;
    line-height: 80px;
    text-align: center;
    color: #FFFFFF;
}

.information-para {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 2rem;
}

@media screen and (max-width: 480px) {

    .bottom-banner {
        padding: 5rem 0;
    }

    .information {
        width:80%;
    }

    .information-header {
        font-size: 32px;
        line-height: 70px;

    }
    
    .information-para {
        font-size: 20px;
        line-height: 32px;
    }


}
