.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 4rem auto;
    width: 100%;
    
}

.splide__slide {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (max-width: 1120px) {
    .home {
        margin: 1rem auto;
        margin-bottom: 4rem;
    }

}
@media screen and (max-width: 750px) {
    .home {
        margin: 0 auto;
        margin-bottom: 3rem;
    }

}

@media screen and (max-width: 550px) {
    .home {
        overflow-x: hidden;
    }
}
