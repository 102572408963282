.register-bar {
    height: 50px;
    position: relative;
    display: flex;
    flex: wrap;
    align-items: center;
    padding-bottom: 3.5rem;
}

.register-bar-select {
    border-width: 1px;
    appearance: none;
    background: url(../../Assets/dropdown-arrow.png) right no-repeat;
    background-position-x: 88%;
    background-size: 10px;
    border-color: #c4c4c4;
    font-size: 1rem;
    border-radius: 8px;
    padding-left: 10px;
    height: 50px;
}

.register-bar-input {
    border: none;
    border-bottom: 2px solid #230ea1;
    margin: 0px 15px;
    outline: none;
    width: 350px;
    height: 50px;
    font-size: 16px;
    font-family: Montserrat;

}

.register {
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    color: white;
    right: 9px;
    padding-right: 12px;
    padding-left: 12px;
    width: 106px;
    height: 50px;
    border-radius: 8px;
    background: #3966FB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}
.center-register {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 700;
    color: white;
    right: 9px;
    padding-right: 12px;
    padding-left: 12px;
    width: 106px;
    height: 50px;
    border-radius: 8px;
    background: #3966FB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: auto;
    margin-top: 2rem;
    width: 320px;
}
.register:hover,
.center-register:hover {
    opacity: 0.85;
}

@media screen and (max-width: 680px) {
    .register {
        display: none;
    }
    .center-register {
        display: block;
    }
}

@media screen and (max-width: 550px) {
    .register-bar-input {
        width: 260px;
    }
}

@media screen and (max-width: 375px) {
    .register-bar-input {
        width: 240px;
    }
}
